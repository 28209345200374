import {
    FooterWrapper,
    FooterContainer,
    FooterTop,
    FooterContact,
    FooterContactTitle,
    FooterInfo,
    FooterInfoTitle,
    FooterInfoSocials,
    FooterInfoImage,
    FooterInfoDiv,
    FooterInfoLocation,
    FooterInfoAddress,
    FooterSubscribe,
    FooterLinks,
    FooterLink,
    FooterALink,
    FooterUl,
    FooterLi,
    FooterBottom,
    FooterClickable
} from './styled';
import Subscribe from 'components/Subscribe';

import linkedInIcon from 'assets/icons/linkedin-icon.svg';
import facebookIcon from 'assets/icons/facebook-icon.svg';
import xIcon from 'assets/icons/x-icon.svg';
import instagramIcon from 'assets/icons/instagram-icon.svg';
import youtubeIcon from 'assets/icons/youtube-icon.svg';
import ContactUsForm from 'components/ContactUsForm';

// import terms from 'assets/files/terms.pdf';
// import profoundCustomerResponsibilities from 'assets/files/Profound_CustomerResponsibilities.pdf';

interface LinkProps {
    id: number,
    label: string,
    link: string,
    isExternal?: boolean,
}

const socialMedia = () => {
    const data = [
        {
            id: 1,
            image: linkedInIcon,
            link: 'https://www.linkedin.com/company/profound-technologies',
        },
        {
            id: 2,
            image: facebookIcon,
            link: 'https://www.facebook.com/profoundsimple/',
        },
        {
            id: 3,
            image: xIcon,
            link: 'https://twitter.com/profoundsimple',
        },
        {
            id: 4,
            image: instagramIcon,
            link: 'https://www.instagram.com/profoundsimple/',
        },
        {
            id: 5,
            image: youtubeIcon,
            link: 'https://www.youtube.com/@profoundsimple',
        },
    ];

    return (
        <FooterInfoSocials>
            {data.map((item) => (
                <FooterLink key={item.id} to={item.link} target="_blank">
                    <FooterInfoImage
                        src={item.image}
                        alt={item.image}
                        loading="lazy"
                    />
                </FooterLink>
            ))}
        </FooterInfoSocials>
    );
};

const addresses = () => {
    const data = [
        {
            id: 1,
            location: 'East Coast',
            address: '125 Little Conestoga Road, Chester Springs, PA 19425',
            phoneNumber: '(877) 208-7308',
            phoneURl:'tel:8772087308',
        },
        {
            id: 2,
            location: 'West Coast ',
            address: '10600 Shoemaker Ave, Unit C, Santa Fe Springs, CA 90670',
            phoneNumber: '(877) 208-7308',
            phoneURl:'tel:8772087308',
        },
    ];

    return data.map((item) => (
        <FooterInfoDiv key={item.id}>
            <FooterInfoLocation>{item.location}</FooterInfoLocation>
            <FooterInfoAddress>{item.address}</FooterInfoAddress>
            <FooterClickable href={item.phoneURl}>{item.phoneNumber}</FooterClickable>
        </FooterInfoDiv>
    ));
};

const links: any = [
    [
        {
            id: 1,
            label: 'Blog',
            link: 'https://blog.profound-tech.com/blog',
            isExternal: true,
        },
        {
            id: 2,
            label: 'Product Review',
            link: '/product-reviews',
        },
    ],
    [
        {
            id: 3,
            label: 'Design Hub',
            link: '/design-hub',
            isExternal: false,
        },
        {
            id: 4,
            label: 'Services Hub',
            link: '/services-hub',
        }
    ],
    [
        {
            id: 5,
            label: 'About Us',
            link: '/about-us',
        },
        {
            id: 6,
            label: 'Contact Us',
            link: '/contact-us',
        },
        {
            id: 7,
            label: 'Career',
            link: 'https://profoundtech.freshteam.com/jobs',
        },
    ]
];

const files = {
    terms: '/static/files/terms.pdf',
    customerResponsibilities: '/static/files/customer-responsibilities.pdf'
}


const bottomLinks = [
    {
        id: 1,
        label: 'Privacy Policy',
        link: '/privacy-policy',
    },
    {
        id: 2,
        label: 'Terms',
        // isDownload: true,
        link: files.terms,
        isExternal: true,
    },
    {
        id: 3,
        label: 'Customer Responsibilities',
        link: files.customerResponsibilities,
        // isDownload: true,
        isExternal: true,
    },
];

function Footer() {
    return (
        <FooterWrapper>
            <FooterContainer className="container">
                <FooterTop>
                    <FooterInfo>
                        <FooterInfoTitle>Follow us on</FooterInfoTitle>
                        {socialMedia()}
                        {addresses()}
                    </FooterInfo>
                    <FooterContact>
                        <FooterContactTitle>Contact</FooterContactTitle>

                        <ContactUsForm isFooter={true} />
                    </FooterContact>
                </FooterTop>
                <FooterSubscribe>
                    <Subscribe
                        title="Subscribe to get the latest from Profound Technologies"
                        isFooter={true}
                    />
                    <FooterLinks>
                        {links.map((link: LinkProps[], index: number) => (
                            <FooterUl key={index}>
                                {link.map((item: LinkProps) => (
                                    <FooterLink
                                        key={item.id}
                                        to={item.link}
                                        target={
                                            item.isExternal ? '_blank' : '_self'
                                        }
                                        className="arrow"
                                    >
                                        <FooterLi>{item.label}</FooterLi>
                                    </FooterLink>
                                ))}
                            </FooterUl>
                        ))}
                    </FooterLinks>
                </FooterSubscribe>
            </FooterContainer>
            <FooterBottom>
                {bottomLinks.slice(0, 1).map((item: any) => (
                    <FooterLink key={item.id} to={item.link ? item.link : ''} download={item.isDownload}>
                        {item.label}
                    </FooterLink>
                ))}
                {bottomLinks.slice(1, bottomLinks.length).map((item: any) => (
                    <FooterALink key={item.id} href={item.link} download={item.isDownload} target={
                        item.isExternal ? '_blank' : '_self'
                    }>
                        {item.label}
                    </FooterALink>
                ))}
            </FooterBottom>
        </FooterWrapper>
    );
}

export default Footer;
