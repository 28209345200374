import { FC, lazy } from 'react';

const Home = lazy(() => import('pages/Home'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const OurServices = lazy(() => import('pages/ServiceHub'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const ProductReviews = lazy(() => import('pages/ProductReviews'));
const ProductReview = lazy(() => import('pages/ProductReview'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const DesignHub = lazy(() => import('pages/DesignHub'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const Downloads = lazy(() => import('pages/Downloads'));
const RoomSurvey = lazy(() => import('pages/RoomSurvey'));
const Agent = lazy(() => import('pages/Agent'));
const CommercialAVSolution = lazy(() => import('pages/ServiceHub/CommercialAVSolution'));
const CrestronAVSolution = lazy(() => import('pages/ServiceHub/CrestronAVSolution'));
const MicrosoftTeamsConferencing = lazy(() => import('pages/ServiceHub/MicrosoftTeamsConferencing'));
const ResidentialAVSolution = lazy(() => import('pages/ServiceHub/ResidentialAVSolution'));
const ZoomConferencingSystems = lazy(() => import('pages/ServiceHub/ZoomConferencingSystems'));
const GovernmentAVSolution = lazy(() => import('pages/ServiceHub/GovernmentAVSolution'));
const OurServicesNewYork = lazy(() => import('pages/ServiceHub/NewYork'));
const OurServicesLosAngeles = lazy(() => import('pages/ServiceHub/LosAngeles'));
const OurServicesWashingtonDC = lazy(() => import('pages/ServiceHub/WashingtonDC'));
const PhiladelphiaAVServices = lazy(() => import('pages/ServiceHub/PhiladelphiaAVServices'));



export interface IRoute {
    url: string;
    Component: FC;
}

export interface IRedirect {
    url: string;
    to: string;
}

export const routes: IRoute[] = [
    { url: '/', Component: Home },
    { url: '/services-hub', Component: OurServices },
    { url: '/about-us', Component: AboutUs },
    { url: '/contact-us', Component: ContactUs },
    { url: '/product-reviews', Component: ProductReviews },
    { url: '/product-reviews/:labelId', Component: ProductReview },
    { url: '/design-hub', Component: DesignHub },
    { url: '/agent', Component: Agent },
    { url: '/privacy-policy', Component: PrivacyPolicy },
    { url: '/downloads', Component: Downloads },
    { url: '/room-survey', Component: RoomSurvey },
    { url: '/services-hub/commercial-av-solution', Component: CommercialAVSolution },
    { url: '/services-hub/crestron-av-solutions', Component: CrestronAVSolution },
    { url: '/services-hub/microsoft-teams-conferencing', Component: MicrosoftTeamsConferencing },
    { url: '/services-hub/residential-av-solutions', Component: ResidentialAVSolution },
    { url: '/services-hub/zoom-conferencing-systems', Component: ZoomConferencingSystems },
    { url: '/services-hub/government-av-solutions', Component: GovernmentAVSolution },
    { url: '/services-hub/new-york-av-services', Component: OurServicesNewYork },
    { url: '/services-hub/los-angeles-av-services', Component: OurServicesLosAngeles },
    { url: '/services-hub/washington-dc-av-services', Component: OurServicesWashingtonDC },
    { url: '/services-hub/philadelphia-av-services', Component: PhiladelphiaAVServices },


    { url: '/*', Component: PageNotFound },
];

export const redirects: IRedirect[] = [
    // links
    { url: '/details', to: '/services-hub' },
    { url: '/details/details', to: '/services-hub' },
    { url: '/secondary/list', to: '/design-hub' }, // create
    { url: '/downloads', to: '/downloads' }, // to be determined
    { url: '/secondary/privacyPolicy', to: '/privacy-policy' },
    { url: '/roomBuilder/Gateway', to: '/room-builder' },
    { url: '/roomBuilder/roomBuilder', to: '/room-builder' },
    { url: '/news', to: '/' },
    { url: '/room-builder', to: '/' },
    { url: '/commercial/building-automation/', to: '/services-hub' },
    { url: '/commercial/cctv/', to: '/services-hub' },
    { url: '/government/9430/Secure-Video-Teleconferencing-Rooms', to: '/services-hub' },
    { url: '/government/boardrooms/', to: '/services-hub' },
    { url: '/process/', to: '/services-hub' },
    { url: '/process/design-documentation/', to: '/services-hub' },
    { url: '/residential/lighting-control/', to: '/services-hub' },
    { url: '/room-builder-logi', to: '/services-hub' },
    { url: '/videos.php/', to: '/services-hub' },

    // anchors
    { url: '/details#what-make-us-successful', to: '/case-studies' },
    { url: '/details#what-we-do', to: '/design-hub' },
    { url: '/details#solutions-we-offer', to: '/services-hub' },
    { url: '/details#solutions-we-offer-our-products', to: '/design-hub' },
    { url: '/details#solutions-we-offer-our-process', to: '/services-hub' },
    { url: '/details#who-we-are', to: '/about-us' },
    { url: '/details#who-we-are-our-passion', to: '/about-us' },
    { url: '/details#who-we-are-our-culture', to: '/about-us' },
    { url: '/details#what-we-do-residential', to: '/services-hub' },
    { url: '/details#what-we-do-government', to: '/services-hub' },

    // PDFs
    { url: '/docs/terms-continued.pdf', to: '/static/files/terms.pdf' },
    { url: '/static/docs/terms.pdf', to: '/static/files/terms.pdf' },
    { url: '/static/docs/docs/old-terms.pdf', to: '/static/files/terms.pdf' },

    { url: '/case-studies/Profound_CaseStudy_Zoom.pdf/', to: '/static/files/case-study-zoom.pdf' },
    { url: '/static/docs/case-studies/Profound_CaseStudy_CapitalNewYork.pdf', to: '/static/files/case-study-capital-new-york.pdf' },
    { url: '/static/docs/case-studies/Profound_CaseStudy_Carltons.pdf', to: '/static/files/case-study-carltons.pdf' },
    { url: '/static/docs/case-studies/Profound_CaseStudy_FL.pdf', to: '/static/files/case-study-fl.pdf' },
    { url: '/static/docs/Profound_References.pdf', to: '/static/files/profound-references.pdf' },
    { url: '/static/docs/simple-conversations/Profound_SimpleConvo_DonH.pdf', to: '/static/files/profound-simple-convo-donh.pdf' },
    { url: '/static/docs/simple-conversations/Profound_SimpleConvo_JeffK.pdf', to: '/static/files/profound-simple-convo-jeff-k.pdf' },
    { url: '/static/docs/simple-conversations/Profound_SimpleConvo_trevorDepp.pdf', to: '/static/files/profound-simple-convo-trevor-depp.pdf' },

    { url: '/static/docs/customer-responsibilities.pdf', to: '/static/files/customer-responsibilities.pdf' },




];
